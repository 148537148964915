<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="7">
                    <h4>Total Fees Collected</h4> 
              </CCol> 
              <CCol md="5">                   
                   <CInput
                      label="Search"                     
                      horizontal
                      v-model="filter" 
                      @change="getFilterData"
                      placeholder="Type to Search" 
                    />            
              </CCol>                
          </CRow>

        </CCardHeader><br/>
        <CCardBody> 

          <CRow>
            <CCol col="12" class="text-left">                   
               <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
            </CCol> 
          </CRow> 

         <div class="text-center">
            <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
         </div>

                     <b-card-group deck class="mb-3" style="width:50%" v-if="formPasswd">  
                        <b-card border-variant="dark" header="Protected Excel Password" align="center">
                        <b-card-text>
                            <b-form-input style="text-align: center" v-model="password" size="md" :readonly="true"></b-form-input>
                        </b-card-text>                                                                      
                        <b-button-group>
                           <b-button v-on:click="actionCancel()">Cancel</b-button>
                           <b-button variant="success" v-on:click="generateWorkbook()">Download</b-button>
                        </b-button-group>                                   
                        </b-card>
                     </b-card-group>

         <CRow>
              <CCol md="5">                   
                   <CInput
                      label="From Date"
                      type="date"
                      horizontal                      
                      v-model="fromDate" 
                      @input="startDateOnChange()" 
                    />            
              </CCol>
              <CCol md="5">                   
                   <CInput
                      label="To Date"
                      type="date"
                      horizontal
                      v-model="toDate" 
                      @input="toDateOnChange()" 
                    />            
              </CCol>  
              
              <CCol md="2">  
                    <!-- <b-dropdown variant="primary" text="Actions" size="md">
                       <b-dropdown-item v-on:click="showFormPasswd()" >Download</b-dropdown-item> 
                       <router-link :to='"/promo/create"' tag="b-dropdown-item" > Create Promo </router-link> 
                   </b-dropdown>                -->
                    <!-- <vue-excel-xlsx 
                        style="color:green"                       
                        :data="data"
                        :columns="columns"
                        :filename="'Fees-Collected-from' + fromDate +'to'+ toDate"
                        :sheetname="'Detail-Fees-Collected'"
                        >
                        Download
                    </vue-excel-xlsx>                  -->      
                    <CButton v-on:click="showFormPasswd()" color="success">Download</CButton>              
              </CCol>                                             
         </CRow>
         
         <table class="table">
          <thead>
            <tr>              
              <th scope="col">Num. Of Transactions</th>
              <th scope="col">Total Fee Collected</th>              
            </tr>
          </thead>
          <tbody>
            <tr>              
              <td>{{ numberOfTransactions }}</td>
              <td>{{ totalFeeCollected | formatNum }}</td>              
            </tr>
          </tbody>
        </table><br/>

                  <div class="table-responsive">
                    <b-table striped hover :items="items" :fields="fields" v-model="list" sort-by="dateTime" sort-desc="true" :current-page="currentPage" :per-page="perPage" 
                      :filter="filter">

                      <template v-slot:cell(promoCode)="{ item }"> 
                          {{ item.promoCode | setDefaultText }}
                      </template>

                      <template v-slot:cell(promoAmount)="{ item }"> 
                          {{ item.promoAmount | setDefaultNum }}
                      </template>

                      <template v-slot:cell(dateTime)="{ item }"> 
                          {{ item.dateTime }}
                      </template>

                      <template v-slot:cell(fee)="{ item }"> 
                          {{ item.fee | formatNum }}
                      </template>  
                     
                    </b-table>
                  </div>

                  <div>
                    <b-row>
                      <b-col sm="3">
                         <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
                      </b-col>       
                      <b-col sm="9">
                         <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
                         </b-form-select>
                      </b-col>
                    </b-row>      
                  </div>
          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ReportService from '@/api/ReportService.js';
import moment from 'moment';

 export default {
    name: "totalfeescollected",
    components: {       
    },     
    data() { var date = new Date();
      return {
        fromDate: new Date(date.getFullYear(), date.getMonth(), 2).toISOString().substr(0, 10),
        toDate: new Date().toISOString().substr(0, 10), 
        spinner:false,
        numberOfTransactions:0,
        totalFeeCollected:0,        
        list:[],
        columns : [],        
        data  : [],        
        items:[],               
        fields: [               
          {           
            key: 'transactionRef',
            label : 'Tr. Reference',
            sortable: true
          }, 
          {            
            key: 'dateTime',
            label : 'Date Time',
            sortable: true
          },
          {           
            key: 'promoCode',
            label : 'Promo Code',
            sortable: true
          },
          {           
            key: 'promoAmount',
            label : 'Promo Amount',
            sortable: true
          },       
          {           
            key: 'fee',
            label : 'Fee',
            sortable: true
          }           
        ],                 
        currentPage: 1,
        perPage: 10,        
        filter: null,
        passpar:'',
        formPasswd:false,
        filename:"fees-collected-report.xlsx",
        password:"",
        seen:false,
        msg :'',
        color:''
      };
    },    
    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },        
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        getGeneraldate(value){
            var dt  = new Date(value);
            var dt2 = dt.toISOString();
            var temp = dt2.split(".");
            value = temp[0].replace("T"," ");
            return value;
        },
        formatNum(value){ 
           if(!value) return 0  
           return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")          
        },
        setDefaultNum(value){
           if(!value) return 0           
           return value;  
        },
        setDefaultText(value){
           if(!value) return 'none'          
           return value;  
        },

    },

    created () { 
       this.spinner = true;
       this.setDefaultPasswd(); let params = {};
       params['fromDate'] = this.fromDate+"T00:00:00";
       params['toDate'] =  this.toDate+"T23:59:59";
       this.getTotalFeesCollected(params);
       this.setColumns(this.fields);       
    },
    methods: { 

      showFormPasswd(){
         this.formPasswd = true;
      },

      actionCancel(){
          this.formPasswd = false;
      },
       
      setDefaultPasswd(){
        var today = moment().format('DDMMYYYYHHmmss');
        var user  = this.$store.getters.getUser; 
        this.password = user+today;
      },
      
      generateWorkbook(){

         this.formPasswd = false; this.spinner=true;
         var cols  = this.columns; var rows  = this.data;        
         var heads = []; var temp =[]; var data=[];
         let params = {};  var colkey =[];         

          for (var key in cols) {
             var col = cols[key]; heads.push(col.label);
             colkey.push(col.field);
          }           
          data.push(heads);  var jum = heads.length;          

          for (var key2 in rows) { var row = rows[key2]; 
              var init = Array(jum).fill(null);
              init[3] = 0;
              //console.log(init);
              for(var key4 in colkey){
                for (var key3 in row) { 
                  var val  = row[key3];                                
                  var ckey = colkey[key4];
                  if(key3===ckey){  init[key4] = (val==='') ? null : val;  }              
                }
              }
              data.push(init); 
          } 

         params['password'] = this.password;
         params['data'] = data;   //console.log(params); 
         this.getReportExcel(params);
      },

      getReportExcel: function(params) {
        ReportService.getReportExcel(params).then(resp => {             
         try {  
            if(resp.status==200){
               var filename = this.filename;
               const link = document.createElement('a');    
               link.href = window.URL.createObjectURL(
                   new Blob([resp.data])
               );   
               link.setAttribute('download', filename);
               document.body.appendChild(link);
               link.click(); this.spinner = false; 
            } else {
              this.msg   = 'Sorry, Generated Excel Failed!'; 
              this.color = 'warning'; this.seen  = true;
              this.spinner = false; 
              setTimeout( () =>  this.seen=false , 5000);
            }
           }
         catch(err) {            
            this.msg   = 'Sorry, Generated Excel Failed!'; 
            this.color = 'warning'; this.seen  = true;
            this.spinner = false; 
            setTimeout( () =>  this.seen=false , 5000);
         } 

        }, error => { 
           this.spinner = false;            
        });
      }, 

      formatNum(value){
         return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") 
      }, 

      dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
      },  

      setDataRows(resp,keydate,keyamount){

          var tzoffset = (new Date()).getTimezoneOffset() * 60000;

          for (var key in resp) {                
              var resp2 = resp[key];  
                for (var key2 in resp2) { 
                    if(key2 === keydate){ 
                      var strdate = resp2[key2]; 
                      //var dt = new Date(strdate).toISOString().substr(0, 19).replace("T"," ");
                      var ts = new Date(strdate).getTime();
                      var dt = (new Date(ts-tzoffset)).toISOString().substr(0, 19).replace("T"," ");
                      resp[key][keydate] = dt; 
                    }
                    if(keyamount.includes(key2)){
                        resp[key][key2] = !resp2[key2] ? 0 : resp2[key2];
                    }
                    // if(key2 === keyamount){ 
                    //    resp[key][keyamount] = resp2[key2];
                    // }
                 }
            }

          //console.log(resp);

          this.data  = resp;
      }, 

      getFilterData(){          
         var data  = this.list;
         this.setDataRows(data,'dateTime','fee');
      },

      setColumns(fields){
        let row = {}; var cols = [];
        for (var key in fields) {           
           row['label'] = fields[key].label;
           row['field'] = fields[key].key;
           var temp = JSON.stringify(row); cols.push(temp);
        }        

        for (var key2 in cols) {               
           var row2 = JSON.parse(cols[key2]); 
           this.columns.push(row2);           
        }
      },

      getRequestParams(){ 
          let params = {};
          params['fromDate'] = this.fromDate+"T00:00:00";
          params['toDate']   = this.toDate+"T23:59:59";         
          this.passpar = params;
      },

      startDateOnChange(){
        this.spinner = true;
        this.getRequestParams();
        this.getTotalFeesCollected(this.passpar); 
      },

      toDateOnChange(){
        this.spinner = true;
        this.getRequestParams();
        this.getTotalFeesCollected(this.passpar); 
      },    

      getTotalFeesCollected: function(params) {
        ReportService.getTotalFeesCollected(params).then(resp => {   //console.log(resp);
            
            this.numberOfTransactions = resp.numberOfTransactions;
            this.totalFeeCollected = resp.totalFeeCollected;

            this.items   = resp.transactionList;
            var data     = resp.transactionList;

            var keyamount = ["fee", "promoAmount"];
            this.setDataRows(data,'dateTime',keyamount);

            this.spinner = false;

        }, error => {
           this.loading = false;
        });
      },

    }
 }; 

</script>